.langFr{
    /* height:37px; */
    /* margin:0 0 177.7px 0; */
    font-size:20px;
    line-height:1.85;
    letter-spacing:normal;
    color:#1f0d3c;
}
header{
    margin: 0 0 120px 0;
    z-index: 100;
}
.langAr{
    /* height:37px; */
    /* margin: 0 0 177.7px 0; */
    font-size: 20px;
    line-height: 1.85;
    letter-spacing:normal;
    color:#fec868;
}
.ullist{
    margin-left: 249px;
}
#nav-content {
    padding-right: 18px;
}
.list{
    /* height:37px;  */
    color:#1f0d3c;
    font-size:20px; 
    /* margin:0 24.5px 177.7px 0;  */
    line-height:1.85;
    letter-spacing:normal;
}
.logoImg{
    height: 37px;
}
.SuLogo{
    height: 1vh;
    /* margin: 0 0 177.7px 0; */
}
.labelNavbar{
    width: 36px;
    height: 36px;
    /* margin: 0px 0 146px 0; */
    padding: 8px 6.9px 8px 7.9px;
    border-radius: 7px;
    background-color: #1f0d3c;
    display: none;
}
.labelNavbar svg{
    fill: #fec868;
}

.divGlobal2 header nav a img {
    content: url("../assets/images/logowhite.png");
}

.divGlobalHome2, .divGlobal .logoImg {
    content: url("../assets/images/logowhite.png");
}

.divGlobalHome2, .divGlobal .SuLogo {
    content: url("../assets/images/gggwhite.png");
}

.logoImg {
    content: url("../assets/images/dd.png");
}

.SuLogo {
    content: url("../assets/images/ggg.png");
}



.selected:hover {
    color: #fec868;
}


.ulNavDropdown{
    padding: 10px 19px 12px 16px;
    background-color: #1f0d3c;
    border-radius: 18px;
    margin: 0 0 -132px -130px;
    color: #fff !important;
    font-size: 14px;
    line-height: 1.7;
    z-index: 999;
}


.logosolo {
    content: url("../assets/images/logosolo.png");
}
.auth{
    text-align: -webkit-center;
}

.header{
    /* margin: 0 0 129px 0; */
}

.menu{
    display: flex;
}

/* 787 */



.liMar{
    margin: 0 36px -13px 0;
}





/* ------------------------Media Query---------------------- */
@media screen and (max-width: 984px) and (min-width: 940px) {
    .liMar{
        margin: 0 36px 0 0;
    }
}
@media screen and (max-width: 787px)  {
    .labelNavbar{
        display: block;
    }
    .menu{
        display: none !important;
    }
    .header{
        /* margin: 0 0 200px 0; */
    }
    .liMar{
        margin: 0 0 0 0;
    }
    .liMar span{
        font-size: 40px;
    }
}
@media screen and (max-width: 984px) {
    .ullist{
        margin-left: 100px;
    }
    .liMar{
        margin: 0 0 0 0;
    }
}
@media screen and (max-width: 940px) {
    .ullist{
        margin-left: 15px;
    }
    .list{
        font-size: 12px;
        /* margin: 0 9.5px 161.7px 0; */
    }
    .langFr{
        font-size: 12px;
        line-height: 2.15;
    }
    .langAr{
        font-size: 12px;
        line-height: 2.15;
    }
    .liMar{
        margin: 0 0 0 0 !important;
    }
}
@media screen and (max-width: 1820px) {
    .langFr{
        font-size: 15px;
        line-height:1.15;
    }
    .langAr{
        font-size: 15px;
        line-height: 1.15;
    }
    .ullist{
        margin-left: 190px;
    }
    .list{
        font-size:18px; 
        line-height:1.15;
    }
    .logoImg{
        height: 30px;
    }
    .liMar{
        margin: 0 0 0 0;
    }
}
@media screen and (max-width: 1115px) {
    .langFr{
        font-size: 12px;
        line-height: 2.15;
    }
    .langAr{
        font-size: 12px;
        line-height: 2.15;
    }
    .ullist{
        margin-left: 150px;
    }
    .list{
        font-size: 15px;
    }
    .logoImg{
        height: 25px;
    }
    .liMar{
        margin: 0 0 0 0;
    }
}
@media screen and (max-width: 786px)  {
    .navMoile{
        height: 120vh; 
        position: fixed;
        background-color: #1f0d3c;
        background-image: url('../assets/images/ff_about.png') !important;
        background-position: bottom;
        background-repeat: repeat;
        background-size: cover;
        top: 0;
        z-index: 5;
    }
    header {
        margin: 0 0 38px 0 !important;
    }
    .svgClose{
        width: 22px;
        margin: 36px 0 0 32px;
    }
    .imgLogo{
        height: 17.1px;
        margin: 32px 0 0 0;
        object-fit: contain;
        width: 230px;
    }
    .SoimgLogo{
        height: 7.1px;
        margin: 4px 0 0 0;
        object-fit: contain;
        width: 233px;
    }
    .list{
        margin: 53px 10px;
        font-size: 30px;
        font-weight: 900;
        line-height: 2.4;
        letter-spacing: normal;
        text-align: right;
        color: #fec868;
    }
    .liMar{
        margin: 0 0 0 0;
    }

    .liMar span{
        font-size: 26px;
    }


    .aevTextdd{
        width: 46px !important;
    }
    .logoSolodd {
        width: 21px !important;
    }
    .auth{
        margin-right: -10px !important;
    }
}




.logoSolodd{
    width: 30px;
    /* transition: width 1s; */
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: ease-in-out;
}

.aevTextdd{
    width: 70px;
}


.auth:hover .SuLogo {
    opacity: 0;
    transition: all .8s ease-in-out;
}

.auth:hover .aevTextdd {
    opacity: 0;
    transition: all .8s ease-in-out;
}

.auth:hover .logoSolodd {
    position: absolute;
    top: 0;
    transition: all .8s ease-in-out;
    transform: translateX(35px);
    width: 45px;
}