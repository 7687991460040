.imgStatics {
    height: 29vh;
}

.paragraphSection2 {
    width: 426px;
    height: 116px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
    display: flex;

}

.divHeader {
    display: inline-flex;
    padding: 0 0 9px 193px;
}

.divH1 {
    width: 208px;
    height: 52px;
    margin: 0 6px 0 0;
    font-size: 28px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: 2.04;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.divSpan {
    width: 54px;
    height: 52px;
    margin: 0 0 0 6px;
    font-family: Cairo;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.04;
    letter-spacing: 2.8px;
    text-align: left;
    color: #1f0d3c;

}

.divH1second {
    /* height: 68px; */
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}


.svgSection {
    width: 30px;
    height: 30px;
    margin: 11px -9px 6px 0;
    padding: 5px;
    fill: #1f0d3c;
}

.svgSpan {
    width: 214px;
    height: 45px;
    margin: 0 0 0 8px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.divM {
    padding-left: 7.75rem;
}

.sectionHref {
    margin: 79px 6px 36px 16px;
    padding: 18.5px 90px;
    border-radius: 48px;
    border: solid 3px #1f0d3c
}

.sectionHrefHovering {
    background-color: #fec868;
    color: #1f0d3c;
    border: none;
    margin: 79px 6px 36px 16px;
    padding: 18.5px 90px;
    border-radius: 48px;
    border: solid 3px #fec868
}

.sectionHrefSpan {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}

.sectionHref1 {
    background-color: #fec868;
    width: 260px;
    height: 82px;
    margin: 79px 0 36px 16px;
    padding: 18.5px 85px 18.5px 86px;
    border-radius: 48px
}

.sectionHrefSpan1 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: left
}

.divSection4 {
    width: 536px;
    height: 453px;
    margin: 34px 0 43px 0;
    padding: 6px 5.9px 54px 85px;
    border-radius: 82px;
    border: solid 4px #1f0d3c;
    margin-right: -82px;
}

.divSection4_2 {
    background-color: #1f0d3c !important;
    color: #fff !important;
}

.paragraphSection4Active {
    color: #fff !important;


}

.divSpanSection4Active {
    color: #fec868 !important;
    fill: #fec868 !important;
}

.divSpanSection4Active2 {
    color: #fff !important;
}

.svgParagraphActive {
    fill: #fff !important;
}



.h2Section4 {
    width: 255px;
    height: 108px;
    margin: 47px 0 30px 0px;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.h2Section4_2 {
    color: #fff !important;
}

.h2Section4Active {
    color: #1f0d3c !important;
}

.imgSection4 {
    /* margin: 0 0 0 24px; */
    height: 123.1px;
}

.divFlex2 {
    justify-content: flex-end;
}

.paragraphSection4 {
    color: #1f0d3c;
    width: 405px;
    height: 119px;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: right;
}

.divSpanSection4 {
    /* width: 107px; */
    height: 33px;
    margin: 0 0 0 4px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}
.fillActive {
    fill: black !important;
}
.svgParagraph {
    width: 24px;
    height: 24px;
    margin: 5px 4px -8px 0;
    padding: 5px;
    fill: #fff;
}


.divSection4Only {
    /* width: 560px; */
    height: 450px;
    margin: 36px 32px 18px 0;
    padding: 6px 5.9px 54px 85px;
    border-radius: 82px;
    background-color: #1f0d3c
}

.h2Section4Only {
    width: 255px;
    height: 108px;
    margin: 47px 48px 45px 0;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.paragraphSection4Only {
    color: #1f0d3c;
    width: 405px;
    height: 119px;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: right;
}

.divSpanSection4Only {
    width: 78px;
    height: 33px;
    margin: 0 32px 0px 4px;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: left;
    color: #fec868;
}

.svgParagraphOnly {
    width: 24px;
    height: 24px;
    margin: 5px 4px -8px 0;
    padding: 5px;
    fill: #fec868;
}


.section5 {
    height: 8vh;
}

.divFlex {
    padding: 0 108px;
}


.divSwiperr {
    display: flex;
    justify-content: center;
}


.parent {
    position: relative;
    top: 0;
    left: 0;
}

.image1 {
    position: relative;
    top: 18px;
    left: -21px;
    width: 217px;
}

.image2 {
    position: absolute;
    top: -10px;
    left: -55px;
    width: 62%;
}

.image4 {
    position: absolute;
    top: -30px;
    left: 55px;
    width: 27%;
}

.image3 {
    position: absolute;
    top: 124px;
    left: 92px;
    width: 79%;
}

.divFlex button svg {
    fill: #1f0d3c;
}








/* -------ANIMATION--------- */
@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);

    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.section2LaptopVeisuble {
    opacity: 0;
}

.section2Laptop {
    animation-duration: 4s;
    animation-name: slideInLeft;
}

.section3Desktop {
    animation-duration: 4s;
    animation-name: slideInRight;
}

.section4Desktop {
    animation-duration: 4s;
    animation-name: slideInRight;
}


.section2Mobile {
    animation-duration: 4s;
    animation-name: slideInLeft;
}

.section3Mobile {
    animation-duration: 4s;
    animation-name: slideInRight;
}

.section4Mobile {
    animation-duration: 4s;
    animation-name: slideInRight;
}

.section5 {
    animation-duration: 4s;
    animation-name: slideInRight;
}

.image1 {
    animation-duration: 4s;
    animation-name: slideInLeft;
}

.image2 {
    animation-duration: 5s;
    animation-name: slideInRight;
}

.textalign {
    padding-left: 8rem;
}

.image3 {
    animation-duration: 4s;
    animation-name: slideInLeft;
}




@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .imgStatics {
        height: 25vh;
    }

    .divHeader {
        font-size: smaller;
        padding: 0 0 0 0;
    }

    .divH1second {
        padding: 0 0 0 0;
    }

    .block {
        font-size: smaller;
        display: contents;
    }

    .sectionHref {
        margin: 0 0 0 12px;
        padding: 17.5px 30px 18.5px 39px;
        border-radius: 48px;
        border: solid 3px #1f0d3c;
    }

    .sectionHref1 {
        background-color: #fec868;
        width: 260px;
        height: 82px;
        margin: 0 0 0 12px;
        padding: 17.5px 30px 18.5px 39px;
        border-radius: 48px;
    }

    .sectionHrefSpan {
        font-size: 20px;
    }

    .sectionHrefSpan1 {
        font-size: 20px;
    }

    .divSection4 {
        width: 458px;
        height: 457px;
        padding: 0 0 0 0;
    }

    .divSection4Only {
        width: 458px;
        height: 457px;
        padding: 0 0 0 0;
    }

    .divFlex2 {
        justify-content: center;
    }

    .imgSection4 {
        height: 123.1px;
    }

    .h2Section4,
    .h2Section4Only {
        font-size: 24px;
    }

    .paragraphSection2 {
        font-size: 16px;
    }



    .parent {
        position: relative;
        top: 0;
        left: 0;
    }

    .image1 {
        position: relative;
        top: 0;
        left: 0;
        width: 200px;
    }

    .image2 {
        position: absolute;
        top: -30px;
        left: -37px;
        width: 39%;
    }

    .image4 {
        position: absolute;
        top: -35px;
        left: 30px;
        width: 29%;
    }

    .image3 {
        position: absolute;
        top: 85px;
        left: 137px;
        width: 62%;
    }

    .buttons {
        margin: 0 0 0 -100px;
    }
}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .imgStatics {
        height: 22vh;
    }

    .divHeader {
        font-size: smaller;
        padding: 0 0 0 0;
    }

    .divH1second {
        padding: 0 0 0 0;
    }

    .block {
        font-size: smaller;
        display: contents;
    }

    .sectionHref {
        margin: 0 0 0 12px;
        padding: 17.5px 30px 18.5px 39px;
        border-radius: 48px;
        border: solid 3px #1f0d3c;
    }

    .sectionHref1 {
        background-color: #fec868;
        width: 260px;
        height: 82px;
        margin: 0 0 0 12px;
        padding: 17.5px 30px 18.5px 39px;
        border-radius: 48px;
    }

    .sectionHrefSpan {
        font-size: 20px;
    }

    .sectionHrefSpan1 {
        font-size: 20px;
    }

    .divSection4 {
        width: 458px;
        height: 457px;
        padding: 0 0 0 0;
    }

    .divSection4Only {
        width: 458px;
        height: 457px;
        padding: 0 0 0 0;
    }

    .divFlex2 {
        justify-content: center;
    }

    .imgSection4 {
        height: 123.1px;
    }

    .h2Section4,
    .h2Section4Only {
        font-size: 24px;
    }

    .paragraphSection2 {
        font-size: 16px;
    }



    .parent {
        position: relative;
        top: 0;
        left: 0;
    }

    .image1 {
        position: relative;
        top: 0;
        left: 0;
        width: 200px;
    }

    .image2 {
        position: absolute;
        top: -32px;
        left: -38px;
        width: 40%;
    }

    .image3 {
        position: absolute;
        top: 100px;
        left: 150px;
        width: 50%;
    }

    .image4 {
        position: absolute;
        top: -30px;
        left: 35px;
        width: 23%;
    }

    .buttons {
        margin: 0 0 0 -170px;
    }


}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .imgStatics {
        height: 22vh;
    }

    .divHeader {
        font-size: smaller;
        padding: 0 0 0 0;
    }

    .divH1second {
        padding: 0 0 0 0;
    }

    .block {
        font-size: smaller;
        display: contents;
    }

    .sectionHref {
        margin: 0 0 0 12px;
        padding: 17.5px 30px 18.5px 39px;
        border-radius: 48px;
        border: solid 3px #1f0d3c;
    }

    .sectionHref1 {
        background-color: #fec868;
        width: 260px;
        height: 82px;
        margin: 0 0 0 12px;
        padding: 17.5px 30px 18.5px 39px;
        border-radius: 48px;
    }

    .sectionHrefSpan {
        font-size: 20px;
    }

    .sectionHrefSpan1 {
        font-size: 20px;
    }

    .svgSpan {
        font-size: 20px;
        width: 188px;
    }

    .svgSection {
        width: 30px;
        height: 30px;
        margin: 11px -9px 6px 0;
        padding: 5px
    }

    .divSection4 {
        width: 414px;
        height: 431px;
        padding: 0 0 0 0;
    }

    .divSection4Only {
        width: 458px;
        height: 457px;
        padding: 0 0 0 0;
    }

    .divFlex2 {
        justify-content: center;
    }

    .imgSection4 {
        height: 95px;

    }

    .h2Section4,
    .h2Section4Only {
        font-size: 24px;
        height: 90px;
    }

    .paragraphSection2 {
        font-size: 19px;
        text-align: center;
        width: 100%;
    }




    .parent {
        position: relative;
        top: 0;
        left: 0;
    }

    .image1 {
        position: relative;
        top: 0;
        left: 0;
        width: 155px;
    }

    .image2 {
        position: absolute;
        top: -32px;
        left: -38px;
        width: 40%;
    }

    .image3 {
        position: absolute;
        top: 80px;
        left: 113px;
        width: 55%;
    }

    .image4 {
        position: absolute;
        top: -32px;
        left: 20px;
        width: 25%;
    }

    .buttons {
        margin: 0 0 0 -170px;
    }

}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .h2Section4 {
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .imgStatics {
        height: 22vh;
    }

    .textalign {
        padding-left: 10px;
    }

    .paragraphSection2 {
        font-size: 18px;
    }

    .divHeader {
        font-size: 23px;
        padding: 0 0 0 0;
    }

    .divH1second {
        padding: 0 0 0 0;
    }

    .divH1,
    .divSpan {
        display: none;
        font-size: 19px;
    }

    .block {
        font-size: 24px;
        display: contents;
        display: none;
    }

    .section2Laptop {
        grid-column: 2;
    }

    .sectionHref {
        margin: 0 0 0 6px;
        padding: 16.5px 14px 14.5px 23px;
        border-radius: 48px;
        border: solid 3px #1f0d3c;
    }

    .sectionHref1 {
        background-color: #fec868;
        margin: 0 0 0 6px;
        padding: 16.5px 14px 14.5px 23px;
        border-radius: 48px;
    }

    .sectionHrefSpan {
        font-size: 20px;
    }

    .sectionHrefSpan1 {
        font-size: 20px;
    }

    .svgSpan {
        font-size: 20px;
        width: 188px;
    }

    .svgSection {
        width: 30px;
        height: 30px;
        margin: 11px -9px 6px 0;
        padding: 5px
    }

    .divSection4 {
        height: 100%;
        margin: 0px;
        margin-top: 43px;
        font-size: 23px;
        padding: 0;
    }

    .divSection4Only {
        width: 458px;
        height: 457px;
        padding: 0 0 0 0;
    }

    .divFlex2 {
        justify-content: center;
    }

    .imgSection4 {
        height: 95px;
    }

    .h2Section4,
    .h2Section4Only {
        font-size: 24px;
    }

    .paragraphSection2 {
        font-size: 19px;
        text-align: center;
        width: 100%;
    }





    .parent {
        position: relative;
        top: 0;
        left: 0;
    }

    .image1 {
        position: relative;
        top: 0;
        left: 0;
        width: 155px;
    }

    .image2 {
        position: absolute;
        top: -32px;
        left: -38px;
        width: 40%;
    }

    .image3 {
        position: absolute;
        top: 80px;
        left: 113px;
        width: 55%;
    }

    .image4 {
        position: absolute;
        top: -32px;
        left: 20px;
        width: 25%;
    }

    .buttons {
        margin: 0 0 0 -170px;
    }

    .paragraphSection4 {
        font-size: 16px;
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 786px) {
    .section2Laptop {
        display: none !important;
    }

    .section2Mobile {
        display: block !important;
    }

    .divHeadingMobile {
        text-align: end;
        margin: 200px 22px 12px 5px;
    }

    .divHeadingMobile span {
        width: 99px;
        height: 30px;
        margin: 0 6px 0 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 3.56;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .divHeadingMobile h1 {
        height: 81px;
        margin: 0 6px 12px 5px;
        /* padding: 0 0 36px 129px; */
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .divHeadingMobile p {
        height: 70px;
        margin: 0 0 3.5px 22px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .PathImg {
        width: 55%;
    }

    .section3Desktop {
        display: none !important;
    }

    .section3Mobile {
        display: block !important;
    }


    .btn1Section {
        width: 100px;
        height: 36px;
        margin-right: inherit;
        border-radius: 48px;
        background-color: #fec868;
    }

    .btn1Section span {
        font-size: 11px;
        font-weight: 900;
        line-height: 1.9;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .btn1Section2 {
        width: 100px;
        margin-right: inherit;
        height: 36px;
        border-radius: 48px;
        border: solid 2px #1f0d3c;
    }
    .divFix {
        justify-items: center;
        display: flex;
        margin: 19.5px 5px 17px 5px;
        padding: 8px 31px 9px 32px;        
        justify-content: center;
    }
    .btn1Section2 span {
        font-size: 11px;
        font-weight: 900;
        line-height: 1.9;
        letter-spacing: normal;
        text-align: left;
        color: #1f0d3c;
    }

    .section4Desktop {
        display: none !important;
    }

    .section4Mobile {
        display: block !important;
    }

    .sectionMobileCard {
        width: 365px;
        height: 274px;
        margin: 22px 13px 32px;
        padding: 3.3px 4.1px 0 21px;
        border-radius: 62px;
        background-color: #1f0d3c;
    }

    .sectionMobileCard0 {
        width: 13px;
        height: 274px;
        margin: 22px 0 20px -144px;
        border-radius: 62px;
        border: solid 2px #1f0d3c;
    }

    .sectionMobileCard1 {
        width: 13px;
        height: 274px;
        margin: 22px 0 20px 144px;
        border-radius: 62px;
        border: solid 2px #1f0d3c;
    }

    .imgCard1 {
        width: 127.9px;
        height: 128.1px;
        margin: 0px 0 0 25px;
        padding: 22.7px 22.8px 22.4px 22px;
    }

    .h2Section4OnlyMobile {
        width: 143px;
        height: 60px;
        margin: 33.7px 123.9px 7px 48px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .pSection4OnlyMobile {
        width: 299px;
        height: 83px;
        margin: 0 0 11px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        color: #f9f6ff;
    }

    .dot1 {
        width: 10px;
        height: 10px;
        margin: 0 6px 0 0;
        border: solid 2px #1f0d3c;
        border-radius: 100%;
    }

    .dot2 {
        width: 10px;
        height: 10px;
        margin: 0 6px 0 0;
        background-color: #1f0d3c;
        border: solid 2px #1f0d3c;
        border-radius: 100%;
    }

    .svgSpan {
        width: 214px;
        height: 45px;
        margin: 0 0 0 8px;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .svgSection {

        width: 45px;
        height: 27px;


    }

    .paragraphSection2 {
        font-size: 12px;
        text-align: center;
    }



    .section5 {
        display: none;
    }

    .divSection4 {
        /* width: 0; */
        width: 80%;
        height: 100%;
        margin: 0px;
        font-size: 23px;

        padding: 0;



    }

    .imgSection4 {
        height: 60px;
    }

    .paragraphSection4 {
        /* text-align: center; */
        /* padding: 4rem; */
        font-size: 16px;
        width: 100%;
        height: 100%;
    }

    .parent {
        position: relative;
        top: 0;
        left: 0;
    }

    .image1 {
        position: relative;
        top: 0;
        left: 0;
        width: 193px;
    }

    .image2 {
        position: absolute;
        top: -47px;
        left: 149px;
        width: 18%;
    }

    .image3 {
        position: absolute;
        top: 154px;
        left: 397px;
        width: 18%;
    }

    .image4 {
        position: absolute;
        top: -46px;
        left: 242px;
        width: 11%;
    }

    .divSection4Active {
        background-color: #1f0d3c;
    }

    .h2Section4Active {
        color: #fff !important;
    }

    .paragraphSection4Active {
        color: #fff !important;
    }

    .divSpanSection4Active {
        color: #fff !important;
    }

    .svgParagraphAcive {
        fill: #fff;
    }

    .svgParagraph {
        float: right;
    }

    .h2Section4 {
        width: 100%;
        height: 100%;
        margin: 0px;
        font-size: 17px;
    }

}