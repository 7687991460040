.section1 {
    background-image: linear-gradient(to bottom, #f9f6ff, #fbf9ff 33%, #fdfcff);
}

.divGlobalHome {
    background:url("../assets/images/ff.png");
    position: relative;
    background-position:center;
    /* background-size:contain; */
    
    background-repeat:no-repeat;
    height:100vh;
}

.divGlobalHomePage2 {
    background:url("../assets/images/ff_about.png");
    position: relative;
    background-position:center;
    /* background-size:contain; */
    background-repeat:no-repeat;
    height:100vh;
}

.divGlobalMApPage2 {
    background:url("../assets/images/ff_about.png");
    position: relative;
    background-position:center;
    /* background-size:contain; */
    background-repeat:no-repeat;
}
.divGlobal {
    background:url("../assets/images/ff_about.png") !important;
    position: relative !important;
    background-position:center !important;
    background-size:contain !important;
    background-repeat:no-repeat !important;
    height:100vh;
}

.divGlobal2 .langFr {
    color: white !important;
}

.divGlobal2 {
    background:url("../assets/images/ff_about.png");
    position: relative;
    background-position:center;
    background-size:contain;
    background-repeat:no-repeat;
    height:100vh;
}

.divGlobalHome2 .sec2, .divGlobalHome2 .langFr {
    color: white !important;
}

.divGlobal2 .logoImg {
    content: url("../assets/images/logowhite.png");
}

.divGlobal2 .SuLogo {
    content: url("../assets/images/gggwhite.png");
}

.divGlobal .logoImg, .divGlobal3 .logoImg {
    content: url("../assets/images/dd.png");
}

.divGlobalHome .SuLogo, .divGlobal3 .SuLogo {
    content: url("../assets/images/ggg.png");
}

.divGlobal2 .Header_langFr__nn0jB {
    color: white !important;
}




@media screen and (max-width: 1820px) and (min-width: 1115px) {}
@media screen and (max-width: 1115px) and (min-width: 984px) {}
@media screen and (max-width: 984px) and (min-width: 940px) {}
@media screen and (max-width: 940px) and (min-width: 786px) {}

.snapTest{
    
  scroll-snap-type: y mandatory;
}