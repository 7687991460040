.formDiv{
    width: 706px;
    height: 962px;
    padding: 101px 92px 52px;
    border-radius: 40px;
    background-color: #fff;
    margin: 0px 0 0 266px;
}
.inputFullname{
    width: 522px;
    height: 86px;
    margin: 28px 0 40px 0;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
    text-align: end;
    padding: 25px 34px 24px 34px;
}
.inpputEmail{
    width: 522px;
    height: 86px;
    margin: 0 0 40px 0;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
    text-align: end;
    padding: 25px 34px 24px 34px;
}
input::placeholder, textarea::placeholder{
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: end;
  color: rgba(31, 13, 60, 0.45);
}



.inpputSubject{
    width: 522px;
    height: 86px;
    margin: 0 0 40px 0;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
    text-align: end;
    padding: 25px 34px 24px 34px;
}

.inpputMessage{
    width: 522px;
    height: 219px;
    padding: 25px 34px 24px 34px;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
    text-align: end;
}

.submitBtn{
    width: 195px;
    font-size: x-large;
    height: 84px;
    margin: 40px 141px 0 0;
    padding: 16px 68px;
    border-radius: 17px;
    background-color: #fec868;
}


.sendUsTitlte{
    height: 60px;
    font-size: 32px;
    text-align: right;
}

.callUs{
    height: 60px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: right;
    margin: 190px 51px 0px 0px;
}
.paddingTitle {
    padding: 0 76px 0 10px;
}
.h1Title{
    margin: 0px 46px 0px 0px;
    font-size: 80px;
    line-height: 1.1;
    letter-spacing: normal;
    font-weight: bolder;
}

.paragraph{
    height: 78px;
    margin: 21px 0 0px 295px;
    font-size: 23px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
}

.buttonStore{
    height: 10vh;
}

.contact1{
    width: 476px;
    height: 151px;
    padding: 40px 149px 46px 150px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 28px 9px 0 rgb(85 0 255 / 15%);
}

.svgContact{
    width: 25px;
    height: 25px;
}
.contacParagraph{
    width: 147px;
    height: 33px;
    margin: 0 6px 2px 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.83;
    text-align: right;
    color: #1f0d3c;
}
.contactaevma {
    margin: 2px 0 0 62px;
    font-size: 16px;
    line-height: 1.88;
    text-align: left;
    color: #1f0d3c;
    
}
.contactInfoDiv {
    place-items: center;
    text-align: -webkit-center;
    margin-bottom: 80px;
    margin-top: 80px;
}
.contactaevma2 {
    margin: 2px 0 0 38px;
    font-size: 16px;
    line-height: 1.88;
    text-align: left;
    color: #1f0d3c;
}
.contactaevma3 {
    margin: 2px 0 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 0.94;
    text-align: left;
    color: #1f0d3c;
    float: left;
}




@media screen and (max-width: 1820px) and (min-width: 1446px) {
    .paragraph{
        font-size: 15px;
        margin: 22px 0 0px 47px;
    }
    .h1Title{
        font-size: 50px;
    }
    .titleHeader{
        font-size: 20px;
        line-height: 4.79;
    }
    .iconStart{
        width: 80px;
        height: 80px;
    }
    .buttonStore{
        height: 8vh;
    }
    .images{
        top: -112px;
        left: 211px;
    }
    .image1{
        width: 30%;
    }
    
}
@media screen and (max-width: 1445px) and (min-width: 984px) {
    .paragraph{
        font-size: 18px;
        margin: -98px 0 0px 0;
    }
    .paddingTitle {
        padding: 0 !important;
    }
    .formDiv {
        margin: 0px 0 0 102px;
    }
    .h1Title{
        font-size: 60px;
    }
    .titleHeader{
        font-size: 20px;
        line-height: 4.79;
    }
    .iconStart{
        width: 92px;
        height: 92px;
    }
    .buttonStore{
        height: 9vh;
    }
    .images{
        top: -112px;
        left: 211px;
    }
    .image1{
        top: 40px;
        left: 74px;
        width: 74%;
    }
    .contact1{
        width: 317px;
        height: 151px;
        padding: 50px 41px 6px 19px;
        text-align: -webkit-center;
    }
   
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .sectionLaptop{
        display: none;
    }
    .sectionMobile{
        display: block !important;
        margin: 0 0 0 0;
    }

    .callUsMobile{
        height: 33px;
        font-size: 18px;
        line-height: 3.17;
        letter-spacing: normal;
        text-align: end;
        color: #1f0d3c;
    }


    .h1TitleMobile{
        height: 120px;
        margin: 18.5px 1px 24px 115px;
        font-size: 38px;
        font-weight: 900;
        line-height: 1.26;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .formDivMobile{
        width: 342px;
        height: 592px;
        padding: 34px 24px 45px 40px;
        border-radius: 32px;
        background-color: #fff;
    }
    .sendUsTitlteMobile{
        width: 151px;
        height: 49px;
        margin: 0 0 22px 127px;
        font-size: 26px;
        line-height: 2.19;
        text-align: right;
        color: #1f0d3c;
    }
    .inputFullnameMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputEmailMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputSubjectMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputMessageMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }

    .submitBtnMobile{
        width: 176px;
        height: 68px;
        margin: 32px 102px 0 0;
        padding: 16px 68px;
        border-radius: 17px;
        background-color: #fec868;
    }
    input::placeholder, textarea::placeholder{
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: end;
        color: rgba(31, 13, 60, 0.45);
    }

    .divContactMobile{
        width: 342px;
        height: 126px;
        margin: 0 0 32px;
        padding: 31.5px 82px 29.5px 83px;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0 28px 9px 0 rgb(85 0 255 / 15%);
    }
    body{
        background-color: #f9f6ff;
    }

}
@media screen and (max-width: 940px) and (min-width: 786px) {
    .sectionLaptop{
        display: none;
    }
    .sectionMobile{
        display: block !important;
        margin: 0 0 0 0;
    }

    .callUsMobile{
        height: 33px;
        font-size: 18px;
        line-height: 3.17;
        letter-spacing: normal;
        text-align: end;
        color: #1f0d3c;
    }


    .h1TitleMobile{
        height: 120px;
        margin: 18.5px 1px 24px 115px;
        font-size: 38px;
        font-weight: 900;
        line-height: 1.26;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .formDivMobile{
        width: 342px;
        height: 592px;
        padding: 34px 24px 45px 40px;
        border-radius: 32px;
        background-color: #fff;
    }
    .sendUsTitlteMobile{
        width: 151px;
        height: 49px;
        margin: 0 0 22px 127px;
        font-size: 26px;
        line-height: 2.19;
        text-align: right;
        color: #1f0d3c;
    }
    .inputFullnameMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputEmailMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputSubjectMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputMessageMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }

    .submitBtnMobile{
        width: 176px;
        height: 68px;
        margin: 32px 102px 0 0;
        padding: 16px 68px;
        border-radius: 17px;
        background-color: #fec868;
    }
    input::placeholder, textarea::placeholder{
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: end;
        color: rgba(31, 13, 60, 0.45);
    }

    .divContactMobile{
        width: 342px;
        height: 126px;
        margin: 0 0 32px;
        padding: 31.5px 82px 29.5px 83px;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0 28px 9px 0 rgb(85 0 255 / 15%);
    }
    body{
        background-color: #f9f6ff;
    }



    
}

@media screen and (max-width: 786px)  {
    .sectionLaptop{
        display: none;
    }
    .sectionMobile{
        display: block !important;
        margin: 0 0 0 0;
    }

    .callUsMobile{
        height: 33px;
        font-size: 18px;
        line-height: 3.17;
        letter-spacing: normal;
        text-align: end;
        color: #1f0d3c;
    }

    .paddingTitle {
        padding: 0 133px 0 10px !important;
    }
    .h1TitleMobile{
        height: 120px;
        width: max-content;
        margin: 3.5px 1px 0px 84px;
        font-size: 38px;
        font-weight: 900;
        line-height: 1.26;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .formDivMobile{
        width: 342px;
        height: 592px;
        padding: 34px 24px 45px 40px;
        border-radius: 32px;
        background-color: #fff;
    }
    .sendUsTitlteMobile{
        width: 151px;
        height: 49px;
        margin: 0 0 22px 127px;
        font-size: 26px;
        line-height: 2.19;
        text-align: right;
        color: #1f0d3c;
    }
    .inputFullnameMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputEmailMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputSubjectMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }
    .inpputMessageMobile{
        width: 263px;
        height: 72px;
        padding: 0 19px 0 8px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        margin: 13px 0 0 0;
        background-color: #fff;
    }

    .submitBtnMobile{
        width: 176px;
        height: 68px;
        margin: 32px 102px 0 0;
        padding: 16px 68px;
        border-radius: 17px;
        background-color: #fec868;
    }
    input::placeholder, textarea::placeholder{
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: end;
        color: rgba(31, 13, 60, 0.45);
    }

    .divContactMobile{
        width: 342px;
        height: 126px;
        margin: 0 0 32px;
        padding: 31.5px 82px 29.5px 83px;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: 0 28px 9px 0 rgb(85 0 255 / 15%);
    }
    body{
        background-color: #f9f6ff;
    }




}

@media screen and (max-width: 450px)  {
    .paddingTitle {
        padding: 0 !important;
    }
}
.submitBtn:hover{
    background-color: #1f0d3c;
    color: #fff;
}


/* -------ANIMATION--------- */
@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}
@keyframes slideInUp {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
}

@keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
}

header {
    animation-duration: 2s;
    animation-name: slideInLeft;
}
.paddingTitle{
    animation-duration: 2s;
    animation-name: slideInRight;
}
.formDiv{
    animation-duration: 2s;
    animation-name: slideInLeft;
}
.contactInfoDiv{
    animation-duration: 2s;
    animation-name: slideInRight;
}

.contactin{
    padding: 95px 0 29px 0;
    background-color: #fdfcff;
    margin: -57px 0 0 0;
}