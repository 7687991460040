.about {
    background-color: #f9f6ff;
}
.aboutH1_1{
    text-align: center;
    height: 60px;
    font-Size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    font-weight: 500;
    letter-spacing: normal;
}

.aboutH1_2{
    /* height: 173px; */
    font-size: 92px;
    font-size: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    font-weight: 800;
    text-align: center;
}
.divAbout{
    text-align: -webkit-center
}
.divAboutDiv{
    width: 1104px;
    height: 176px;
    margin: 50px 0 0 0;
    border-radius: 54px;
    background-color: #fff;
}

.abtBtn{
    width: 285px;
    height: 126px;
    margin: 27px 153px 0 0;
    padding: 38px 96px 36px;
    border-radius: 34px;
    background-color: #fec868
}
.abtBtn span{
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.04;
}
.h1_btn{
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.04;
    letter-spacing: normal;
    text-align: center;
    margin: 64px 89px 0px 0px;
    width: -webkit-fill-available;
}
.h1_btnMobile::placeholder {
    font-size: xx-small;
}
.h1_btn::-webkit-input-placeholder, .h1_btnMobile::-webkit-input-placeholder
{
      color: rgb(0, 0, 0);  
}

.h1_btn::-moz-placeholder, .h1_btnMobile::-moz-placeholder
{
      color: rgb(0, 0, 0);
}

.h1_btn:-ms-input-placeholder, .h1_btnMobile:-ms-input-placeholder
{
      color: rgb(0, 0, 0); 
}
@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .aboutH1_1, .aboutH1_2 {
        font-size: 57px;
        margin-top: 53px;
    }
    .divAboutDiv{
        /* display: none; */
        width: 864px;
        height: 152px;
        margin: 54px 0 0 0;
        border-radius: 54px;
        background-color: #fff;
    }
    .abtBtn {
        width: 228px;
        height: 94px;
        margin: 30px 123px 0 0;
        border-radius: 34px;
        padding: 16px 55px 0px;
        background-color: #fec868;
    }
    .h1_btn {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.04;
        letter-spacing: normal;
        text-align: center;
        margin: 35px 103px 0 0;
    }
}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .aboutH1_1, .aboutH1_2 {
        font-size: 35px;
    }
    .divAboutDiv{
        display: none;
    }
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .aboutH1_1, .aboutH1_2 {
        font-size: 30px;
    }
    .divAboutDiv{
        display: none;
    }
}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .aboutH1_1, .aboutH1_2 {
        font-size: 20px;
    }
    .divAboutDiv{
        display: none;
    }
}


@media screen and (max-width: 786px)  {
    .section2ABoutDesktop{
        display: none;
    }
    .section2ABoutMobile{
        display: block !important;
    }
    .aboutH1_1Mobile{
        /* height: 15px; */
        /* margin: 23px 0 0 0; */
        font-size: 14px;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }
    .aboutH1_2Mobile{
        /* height: 43px; */
        /* margin: 17px 10px 0 0; */
        font-size: 26px;
        font-weight: bold;
        line-height: 1.09;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }

    .divAboutMobile{
        text-align: -webkit-center
    }
    .divAboutDivMobile{
        width: 348px;
        height: 45px;
        margin: 33px 0 21px;
        padding: 6px 28px 5px 7px;
        border-radius: 54px;
        background-color: #fff;
    }
    
.abtBtnMobile{
    width: 74px;
    padding: 1px 18px;
    height: 34px;
    margin: 0 73px 0 0;
    border-radius: 34px;
    background-color: #fec868;
}
.abtBtnMobile span{
    width: 23px;
    height: 13px;
    font-size: 11px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}
.h1_btnMobile{
    height: 11px;
    font-size: 10px;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
    width: -webkit-fill-available !important;
}


}