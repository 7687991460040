.sectionLaptop{

}
.mapSection{
      width: 80%;
  height: 800px;
  /* margin: 111px 0 0; */
  padding: 28px 0 0;
  border-radius: 48px;
  background-color: #f9f6ff;
}
.divGlobal{
    display: flex;
    justify-content: center;
}
.buttonCity{
    width: 118px;
    height: 57px;
    border-radius: 29px;
    padding: 17px 23.8px 16px 23px;
    margin: 0 30px;
    background-color: #fec868;
    font-weight: 800;
    display: flex;
    justify-content: center;
    place-items: center;
}
.spanCity{
    font-size: 20px;
    font-weight: bolder;
    padding-left: 5px;
    color: #1f0d3c;
}
.svgCity{
    width: 30px;
    height: 40px;
}

.buttonSelectCity{
    border-radius: 29px;
    background-color: #fff;
    display: flex;
    margin: 0 0 0 0;
    font-weight: 800;
    text-align-last: center;
    width: 273px;
}

.selected:hover {
    color: #fec868;
}
.selected {
    font-weight: 800;
}
.ulNavDropdown{
    width: 175px;
    height: 124px;
    margin: 0 15px 6px 590px;
    padding: 24px 9px 10px 38px;
    border-radius: 30px;
    z-index: initial;
    background-color: #fff;
    position: absolute;
    top: 37rem;
    left: 13rem;
}


.spanSelectedCity{
    width: 75px;
    height: 37px;
    margin: 0 51px 0 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}
.buttonSelectCity option{
    width: 75px;
    height: 37px;
    margin: 0 51px 0 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}
option:checked { 
    width: 75px;
    height: 37px;
    margin: 0 51px 0 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}
.logoAevSvg{
    width: 18px;
    height: 19px;
}
.buttonCityArea {
    width: 158px !important;
}
.spanArea{
    font-size: 20px;
    font-weight: bolder;
    font-weight: 800;
    padding-left: 5px;
    color: #1f0d3c;
}

.secondDiv{
    height: 693px;
    margin: 22px 0 0;
    padding: 26px 23px 19px;
    border-radius: 48px;
    background-color: #fff;
}
.mapGoogle{
    width: 100%;
    height: 640px;
    border-radius: 48px;
}


@media screen and (max-width: 786px) {
    .buttonCity{
        width:110px;
        margin: 0 4px;
        padding: 10px 20px 10px 20px;
    }
    .spanCit{
        font-size: 10px;
        padding-left: 2px;
    }
    .svgCity {
        width: 15px;
        height: 15px;
    }
    .logoAevSvg{
        width: 15px;
        height: 15px;
    }
    .spanArea {
        font-size: 10px;
        padding-left: 2px;
    }

    .spanCity {
        font-size: 11px;
        padding-left: 4px;
    }
    .changeDisplay {
        display: block;
    }
    .flexDisplay{
        margin: 0 0 12px 0;
        justify-content: center;
    }

    .buttonSelectCity {
        width: 173px;
        font-size: 10px;
    }

    .ulNavDropdown{
        display: none;
    }
    
}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .ulNavDropdown{
        display: none;
    }
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .ulNavDropdown{
        display: none;
    }
}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .ulNavDropdown{
        display: none;
    }
}

@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .ulNavDropdown{
        display: none;
    }
}