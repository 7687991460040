.images {
    z-index: 1;
    display: flex;
    margin: -59px 0 0 0;
    justify-content: center;
}

img,
video {
    max-width: 200% !important;
}

.sectionLaptop {
    margin: -80px 0 0 0;
    opacity: 1;
}

body {
    background-color: #f9f6ff !important;
}

.image1 {
    width: 65%;
}

.titleHeader {
    margin: 3px 12px 4px 0;
    font-family: Cairo;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.79;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}

.iconStart {
    width: 84px;
    height: 84px;
    margin-right: 33px;
    padding: 6px;
}

.h1Title {
    margin: 18px 0 0 0;
    height: 216px;
    font-size: 80px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    color: #1f0d3c;
}

.paddingTitle {
    padding-right: 80px;
}

.paragraph {
    font-size: 23px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.buttonStore {
    height: 10vh;
}



.swiper-slide-active>div>div {
    background-color: #1f0d3c !important;
}





/* -------ANIMATION--------- */
@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}


.section2LaptopVeisuble {
    opacity: 0;
}

.sectionLaptop {
    animation-duration: 4s;
    animation-name: slideInLeft;
    /* padding: 100px 0 0 0; */
    opacity: 1;
}

header {
    animation-duration: 2s;
    animation-name: slideInLeft;
}

.images {
    animation-duration: 2s;
    animation-name: slideInUp;
}

.divH {
    animation-duration: 2s;
    animation-name: slideInRight;
}

.buyBtn {
    border-radius: 25px;
    background-color: #1f0d3c;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 178px;
    height: 92px;
    margin: 38px 0 0 0;
    display: flex;
    flex-direction: column;
}

.buyBtn:hover .iconDown1Span {
    opacity: 0;
    transition: all .8s ease-in-out;
}

.buyBtn:hover .iconDown2Span {
    opacity: 0;
    transition: all .8s ease-in-out;
}

.buyBtn:hover .icondown {
    position: relative;
    top: 0;
    transition: all .8s ease-in-out;
    transform: translateX(30px);
}

.buyBtn:hover .platform {
    opacity: 0;
    transition: all .8s ease-in-out;
}

.icondown {
    width: 42px;
    height: 42px;
    /* margin: -7px 0 0 0; */
    fill: #fff;
}

.iconDown1Span {
    font-size: 9px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.iconDown2Span {
    margin: 15px 2px 0 0;
    font-size: 14px;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}


.Ellipse {
    width: 45px;
    height: 45px;
    border: solid 2px #fec868;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.Ellipse:hover {
    background-color: #a996cf;
}

.Ellipse6 {
    width: 40px;
    height: 40px;
    /* padding: 11.3px 9.2px 10.3px 14.4px; */
    box-shadow: 0 3px 6px 0 #a996cf;
    border: solid 2px #a996cf;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}




.parent {
    position: relative;
    top: 30px;
    left: 0;
}

.image1 {
    position: relative;
    top: 334px;
    left: 263px;
    width: 92%;
}

.image2 {
    position: absolute;
    top: 88px;
    left: -43px;
    width: 127%;
}

.image3 {
    position: absolute;
    top: 79px;
    left: 244px;
    width: 95%;
}

.image1 {
    animation-duration: 3s;
    animation-name: slideInLeft;
}

.image2 {
    animation-duration: 5s;
    animation-name: slideInRight;
}

.image3 {
    animation-duration: 5s;
    animation-name: slideInUp;
}




.platform {
    width: 78px;
    height: 13px;
    margin: -4px 29px 0 0;
    font-family: ProductSans;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}




@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .paragraph {
        font-size: 15px;
        margin: 22px 0 0px 47px;
    }

    .image2 {
        top: 0px;
        left: 0px;
        width: 88%;
    }

    .image1 {
        top: 168px;
        left: 263px;
        width: 60%;
    }

    .h1Title {
        font-size: 50px;
        height: 100%;
    }

    .titleHeader {
        font-size: 20px;
        line-height: 4.79;
    }

    .iconStart {
        width: 80px;
        height: 80px;
    }

    .buttonStore {
        height: 8vh;
    }

    .images {
        top: -112px;
        left: 211px;
    }
}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .paragraph {
        font-size: 18px;
        margin: 8px 0 0px 0;
    }

    .image2 {
        width: 80%;
        top: 0;
        left: 0;
    }

    .image1 {
        top: 119px;
        left: 208px;
        width: 64%;
    }

    .h1Title {
        font-size: 50px;
        height: 100%;
    }

    .titleHeader {
        font-size: 15px;
        line-height: 4.79;
    }

    .paragraph {
        font-size: 15px;
        margin: 20px 0 0px 54px;
    }

    .iconStart {
        width: 92px;
        height: 92px;
    }

    .buttonStore {
        height: 9vh;
    }


    .images {
        top: -112px;
        left: 211px;
    }

    /* .image1 {
        width: 84%;
        margin: 10px 0 0 -101px;
    } */

}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .paragraph {
        font-size: 15px;
        margin: 20px 0 0px 54px;
    }

    .h1Title {
        font-size: 43px;
    }

    .titleHeader {
        font-size: 19px;
        line-height: 1.79;
    }

    .iconStart {
        width: 69px;
        height: 69px;
    }

    .buttonStore {
        height: 6vh;
    }

    .images {
        top: -125px;
        left: 240px;
    }

    .image1 {
        width: 71%;
        /* margin: 0 0 0 -149px; */
    }
}

@media screen and (max-width: 940px) and (min-width: 786px) {

    .paragraph {
        font-size: 12px;
        margin: 20px 0 0px 54px;
    }

    .sectionLaptop {
        padding: 100px 0 0 0;
    }

    .image2 {
        position: absolute;
        top: 13px;
        left: 0;
        width: 93%;
    }

    .image1 {
        position: relative;
        top: 133px;
        left: 165px;
        width: 65%;
    }

    .h1Title {
        font-size: 33px;
        height: 91px;
    }

    .titleHeader {
        font-size: 13px;
        line-height: 1.79;
        margin: 0 0 0 0;
    }

    .iconStart {
        width: 49px;
        height: 48px;
    }

    .buttonStore {
        height: 6vh;
    }

    .images {
        top: -125px;
        left: 240px;
    }

    /* .image1 {
        width: 65%;
        /* margin: 0 0 0 -149px;     */

    /* 
    .sectionLaptop {
        margin: 0 0 0 0;
    } */

    .buyBtn {
        width: 159px;
        height: 67px;
    }

    .icondown {
        width: 22px;
        height: 22px;
        margin: 0 0 0 0;
    }

    .platform {
        width: 78px;
        height: 5px;
        margin: 0 0 0 0;
    }

    .iconDown2Span {
        font-size: 12px;
    }

    .iconDown1Span {
        font-size: 7px;
    }

    .textBuybtn {
        margin: 0 0 0 -5px;
    }
}

@media screen and (max-width: 786px) {
    .sectionMobile {
        display: block !important;
    }

    .gridSection1 {
        align-items: flex-end;
        margin-top: -144px;
    }

    .images {
        position: unset;
        z-index: 1;
    }

    .sectionLaptop {
        display: none;
        margin: -80px 0 0 0;
    }

    .H1Section1Mobile {
        color: #1f0d3c;
        width: 100%;
        height: 116px;
        font-size: 36px;
        font-style: normal;
        line-height: 1.33;
        text-align: center;
        font-weight: bolder;
    }

    .image1 {
        display: none;
    }

    .pSection1Mobile {
        width: 100%;
        height: 64px;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }

    .titleHeader {
        width: 203px;
        font-family: Cairo;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 5.58;
        letter-spacing: normal;
        text-align: left;
        margin: 0 0 0 0;
        color: #1f0d3c;
    }



    .Ellipse6 {
        width: 25px;
        height: 23px;
        padding: 8px;
    }
    

    .Ellipse {
        width: 33px;
        height: 32px;
        border: solid 2px #fec868;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
    }



    .iconStartMobile {
        width: 70px;
        height: 61px;
        /* border: solid 1px; */
    }

    .titleFlex {
        font-size: 18px;
        /* line-height: 5.58; */
        letter-spacing: normal;
        color: #1f0d3c;
        text-align: right;
    }

    .imageMobile {
        width: 59% !important;
        height: 48vh;
    }

    .downloadBtnPlayStoreMobile {
        margin: -27px 10px 0 3px;
        padding: 6px;
        border-radius: 18px;
        background-color: #1f0d3c;
        width: 32%;
    }

    .appleLogoMobile {
        width: 50px;
        height: 41px;
        margin: -6.7px 0 0;
    }

    .Download_on {
        font-size: 12px;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .App_Store {
        margin: 0.9px 0 0;
        font-size: 12px;
        text-align: left;
        color: #fff;
    }




    .iconDown1SpanMobile {
        font-family: ProductSans;
        font-size: 7px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        /* line-height: 9.57; */
        /* letter-spacing: normal; */
        /* text-align: left; */
        color: #fff;
    }

    .icondownMobile {
        width: 24px;
        height: 28px;
        fill: #fff;
    }

    .icondown {
        width: 32px;
        height: 28px;
        margin: 9px 9px 0 0;
    }

    .iconDown2SpanMobile {
        font-family: ProductSans;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #fff;
    }

    .iconDown2Span {
        font-family: ProductSans;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        margin: 3px 8px 0 0 !important;
        font-style: normal;
        color: #fff;
    }

    .downloadBtnPlayStoreMobile:hover .iconDown1SpanMobile {
        opacity: 0;
        transition: all .8s ease-in-out;
    }

    .downloadBtnPlayStoreMobile:hover .iconDown2SpanMobile {
        opacity: 0;
        transition: all .8s ease-in-out;
    }

    .downloadBtnPlayStoreMobile:hover .platform {
        opacity: 0;
        transition: all .8s ease-in-out;
    }


    .downloadBtnPlayStoreMobile:hover .icondownMobile {
        position: relative;
        top: 0;
        transition: all .8s ease-in-out;
        transform: translateX(25px);
    }

    .paddingTitle {
        /* display: flex;
        align-items: center;
        justify-content: center; */
        padding-right: 0px;
    }

    .platform {
        margin: 0;
        font-family: ProductSans;
        font-size: 9px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .parent {
        margin: 77px 0 77px 0;
    }


    .image1 {
        width: 241px;
    }

    .image2 {
        position: absolute;
        top: -36px;
        left: 133px;
        width: 18%;
    }

    .image3 {
        position: absolute;
        top: 120px;
        left: 374px;
        width: 17%;
    }

    .image4 {
        position: absolute;
        top: -36px;
        left: 235px;
        width: 11%;
    }



    

    .formDovModal {
        width: 100% !important;
        height: 766px;
        padding: 68px 92px 67px;
    }
    
    .h1Form {
        /* height: 100% !important; */
        margin: 0 0 28px 44px;
        font-size: 32px;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }
    
    .fullnameInp {
        width: 100% !important;
        height: 86px;
        margin: 28px 0 0 0;
        padding: 25px 34px 24px 18px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        background-color: #fff;
    }
    
    .fullnameInp::placeholder {
        /* width: 110px; */
        height: 37px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: right;
        color: rgba(31, 13, 60, 0.45);
    }
    
    .phoneNumberInp {
        width: 100% !important;
        height: 86px;
        margin: 28px 0 0 0;
        padding: 25px 34px 24px 18px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        background-color: #fff;
    }
    
    .phoneNumberInp::placeholder {
        height: 37px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: right;
        color: rgba(31, 13, 60, 0.45);
    }
    
    .SeelctOption {
        width: 100% !important;
        height: 86px;
        margin: 28px 0 0 0;
        padding: 25px 34px 24px 18px;
        border-radius: 17px;
        border: solid 2px rgba(31, 13, 60, 0.15);
        background-color: #fff;
    }
    
    .SeelctOption>option {
        width: 100% !important;
        height: 37px;
        margin: 0 0 0 356px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: right;
        color: rgba(31, 13, 60, 0.45);
    }
    





}





.imagesMobile {
    z-index: 1;
    display: block;
    width: 60%;
    margin: 0px 0 130px 0;
}

.image2Mobile {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
}

.image1Mobile {
    position: relative;
    top: 54px;
    left: 38px;
    width: 100%;
}

.imageMobile {
    width: 90%;
    margin: 0 0 27px 0;
    display: flex;
    justify-content: center;
}





.modalSection {
    background-color: rgba(31, 13, 60, 0.18);
}

.formDovModal {
    position: relative;
    z-index: 1;
    width: 706px;
    height: 766px;
    padding: 68px 92px 67px;
    border-radius: 40px;
    background-color: #fff;
}

.h1Form {
    height: 60px;
    margin: 0 0 28px 44px;
    font-size: 32px;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.fullnameInp {
    width: 522px;
    height: 86px;
    margin: 28px 0 0 0;
    padding: 25px 34px 24px 18px;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
}

.fullnameInp::placeholder {
    /* width: 110px; */
    height: 37px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: right;
    color: rgba(31, 13, 60, 0.45);
}

.phoneNumberInp {
    width: 522px;
    height: 86px;
    margin: 28px 0 0 0;
    padding: 25px 34px 24px 18px;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
}

.phoneNumberInp::placeholder {
    height: 37px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: right;
    color: rgba(31, 13, 60, 0.45);
}

.SeelctOption {
    width: 522px;
    height: 86px;
    margin: 28px 0 0 0;
    padding: 25px 34px 24px 18px;
    border-radius: 17px;
    border: solid 2px rgba(31, 13, 60, 0.15);
    background-color: #fff;
}

.SeelctOption>option {
    width: 76px;
    height: 37px;
    margin: 0 0 0 356px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: right;
    color: rgba(31, 13, 60, 0.45);
}

.subBtn {
    width: 197px;
    height: 84px;
    margin: 31px 44px 0 0;
    padding: 16px 68px;
    border-radius: 17px;
    background-color: #fec868;
}

.subBtnSpan {
    width: 61px;
    height: 52px;
    font-size: 28px;
    line-height: 2.04;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}
