.aboutSection{
    /* width: 725px;
    height: 699px;
    margin: 0 135px 0 42.8px;
    padding: 36px 10px 36.2px 25.8px */
}
.aboutSection img{
    /* margin: 0 156px 0 91.8px; */
    width: 100%; 
    /* padding: 52px 0 0 0; */
}

.divSectionAbout{
    /* height: 47px;
    margin: 125px 124px 20px 114px; */
    font-size: 28px;
    margin-top: 10px;
    line-height: 2.04;
    letter-spacing: 2.8px;
    text-align: right;
    margin: 0 35px 0 0;
}

.divSectionAbout h1{
    /* height: 116px;
    margin: 20px 0 28px 0;
    padding: 0 0 48px 193px; */
    font-Size: 36px;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.33;
}
.divSectionAbout p{
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 10px;
    line-height: 1.61;
    letter-spacing: normal;
}

.paragraphDiv {
    margin-right: 10px;
}

@media screen and (max-width: 1820px) and (min-width: 940px) {
    .paragraphDiv {
        /* padding-right: 130px !important; */
        font-size: 20px !important;
    }
    .aboutSection{
        /* width: 425px !important; */
    }

    .divSectionAbout h1 {
        font-size: 20px !important;
    }
    .divSectionAbout p{
        font-size: 20px;
    }
}

@media screen and (max-width: 940px) and (min-width: 786px) {
    /* .paragraphDiv {
        display:  none;
    } */
    .aboutSection{
        /* width: 600px !important; */
    }

    /* .divSectionAbout h1 {
        display: none;
    }

    .divSectionAbout span {
        display: none;
    } */
    .divSectionAbout p{
        font-size: 18px;
    }
}

@media screen and (max-width: 786px)  {
    .aboutSectionDesktop{
        display: none;
    }
    .aboutSectionMobile{
        display: block !important;
    }

    
    .aboutSectionDiv{
        text-align: end; 
        /* margin: 100px 22px 12px 5px; */
    }
    .aboutSectionDiv span{
        margin: 38px 13px 4px 7px;
        font-size: 19px;
        line-height: 3.56;
        letter-spacing: 1.6px;
        text-align: right;
        color: #1f0d3c;;
    }
    .aboutSectionDiv h1{
        height: 81px;
        margin: 0 6px 12px 5px;
        padding: 0 0 36px 129px;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }
    .aboutImg{
        width: 65%;
    }
    .aboutParagraph{
        margin: 19px 12px 22px 57.5px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        /* padding: 0 0 0 64px; */
    }
    .headtingSpan{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: normal;
    }
    .mapBtn{
        width: 85px !important;
        height: 41px !important;
        border-radius: 18px;
        background-color: #1f0d3c;
    }
    .aevMap{
        margin: -9px 0 0 -11px !important;
        font-size: 12px !important;
        line-height: 1.3 !important;
        letter-spacing: normal !important;
        text-align: left !important;
        color: #fff !important;
    }
    .svgMap{
        width: 14px !important;
        margin: -16px 0 0 -9px !important;
        height: 29px !important;
    }
    .divSectionAbout p{
        font-size: 15px;
    }
}
.mapBtn{
    margin: 10px 0 0 0;
    padding: 23px 24.3px 17.1px 18.5px;
    border-radius: 18px;
    background-color: #1f0d3c;
}
.aevMap{
    width: 75px;
    height: 38px;
    margin: 6px 0 1px -7.100000000000001px;
    font-size: 18px;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.svgMap{
    width: 55.2px;
    height: 31.200000000000003px;
}


/* -------ANIMATION--------- */
@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}
@keyframes slideInUp {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
}

@keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
}
.aboutSection img{
    animation-duration: 5s;
    animation-name: slideInLeft;
}
.divSectionAbout{
    animation-duration: 6s;
    animation-name: slideInRight;
}

/* -------ANIMATION--------- */
@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}
@keyframes slideInUp {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
}
@keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
}

.aboutSectionDesktop{
    animation-duration: 2s;
    animation-name: slideInLeft;
}




.section2LaptopVeisuble {
    opacity: 0;
}