@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

* { font-family: 'Cairo', sans-serif;}

*{scroll-behavior: smooth;}


@media screen and (max-width: 786px) {
    .swiper-pagination-bullet {
        /* background-color: #1f0d3c !important; */
    }
}