.headerH1 {
    /* height: 286px; */
    margin: 0 0 0 0;
    /* font-size: 92px; */
    font-size: 92px;
    font-weight: bold;
    line-height: 1.23;
    text-align: center;
    padding: 8px 212px;
}

.aboutDef {
    padding-top: 100px;
    background-color: #fff;
}

.phonesDiv {
    /* background-color: #f9f6ff; */
    padding-bottom: 100px;
}

.svgplay {
    width: 65px;
    height: 65px;
    margin: 0 0 0 8px;
    padding: 7px;
    border: solid 1px #fec868;
    border-radius: 1000%;
}

.playText {
    margin: 4px 8px 4px 0;
    font-size: 24px;
    line-height: 4.79;
    text-align: left;
}

.phone1 {
    width: 438px;
    height: 811px;
    margin: 0 0 0 82px;
}

.aboutRec {
    width: 1104px;
    height: 350px;
    margin: 0px 0 0 83px;
    padding: 33px 52px 9px 33px;
    border-radius: 62px;
    background-color: #f9f6ff;
}

.headerDIv {
    background-color: #f9f6ffa0;
    overflow: hidden;
}

.aboutPH1 p {
    /* height: 52px;
    margin: 0 6px 0 0; */
    font-size: 28px;
    font-weight: 600;
    line-height: 2.04;
    text-align: right;
}

.aboutPH1 h1 {
    /* height: 116px;
    margin: 8px 0 132px 118px; */
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
}

.paragraphAbtPage {
    /* width: 645px; */
    height: 308px;
    margin: 0 0 0 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: right;
}

@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .aboutRec {
        width: 100%;
        height: 297px;
        margin: 0px 0 0 0;
    }

    .aboutPH1 p {
        font-size: 20px;
    }

    .aboutPH1 h1 {
        font-size: 28px;
    }

    .paragraphAbtPage {
        font-size: 18px;
    }

    .phone1 {
        margin: 0 0 0 0;
        width: 100%;
    }
}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .section1Desktop {
        display: none;
    }

    .section1Mobile {
        display: block !important;
    }

    .h1TitleDiv h1 {
        /* height: 164px; */
        margin: 0 0 28px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }

    .titleFlex {
        font-size: 14px;
        letter-spacing: normal;
        color: #1f0d3c;
        text-align: right;
    }

    .iconStartMobile {
        width: 56px;
        height: 56px;
    }

    .phone1Mobile {
        width: 100%;
        margin: 0 0 0 0;
    }

    .titleAfterImg {
        text-align: end;
    }

    .titleAfterImg span {
        margin: 30px 41px 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 3.56;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .titleAfterImg h1 {
        height: 81px;
        margin: 0px 41px 12px 0;
        padding: 0 0 36px 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .paragraphSec1Mobile {
        height: 63px;
        margin: 0 45px 28px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    body {
        background-color: #f9f6ff !important;
    }

    .paragraphSec1Mobile2 {
        /* height: 202.2px; */
        margin: 0 45px 28px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 50px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 82.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        width: 261px;
    }
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .section1Desktop {
        display: none;
    }

    .section1Mobile {
        display: block !important;
    }

    .h1TitleDiv h1 {
        /* height: 164px;
        margin: -114px 41px -53px; */
        margin: 0 0 28px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }

    .titleFlex {
        font-size: 14px;
        letter-spacing: normal;
        color: #1f0d3c;
        text-align: right;
    }

    .iconStartMobile {
        width: 56px;
        height: 56px;
    }

    .phone1Mobile {
        width: 100%;
        margin: 0 0 0 0;
    }

    .titleAfterImg {
        text-align: end;
    }

    .titleAfterImg span {
        margin: 30px 41px 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 3.56;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .titleAfterImg h1 {
        height: 81px;
        margin: 0px 41px 12px 0;
        padding: 0 0 36px 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .paragraphSec1Mobile {
        height: 63px;
        margin: 11.4px 41px 0 14.5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    body {
        background-color: #f9f6ff !important;
    }

    .paragraphSec1Mobile2 {
        /* height: 202.2px; */
        margin: 11.4px 41px 0 14.5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 50px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 82.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        width: 261px;
    }

    .downApp {
        display: none !important;
    }
}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .section1Desktop {
        display: none;
    }

    .section1Mobile {
        display: block !important;
    }

    .h1TitleDiv h1 {
        /* height: 164px;
        margin: -114px 41px -53px; */
        margin: 0 0 28px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }

    .titleFlex {
        font-size: 14px;
        letter-spacing: normal;
        color: #1f0d3c;
        text-align: right;
    }

    .iconStartMobile {
        width: 56px;
        height: 56px;
    }

    .phone1Mobile {
        width: 100%;
        margin: 0 0 0 0;
    }

    .titleAfterImg {
        text-align: end;
    }

    .titleAfterImg span {
        /* margin: 30px 41px 0; */
        font-size: 18px;
        font-weight: 600;
        line-height: 3.56;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .titleAfterImg h1 {
        height: 81px;
        margin: 0px 41px 12px 0;
        padding: 0 0 36px 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .paragraphSec1Mobile {
        height: 63px;
        margin: 0 45px 28px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    body {
        background-color: #f9f6ff !important;
    }

    .paragraphSec1Mobile2 {
        /* height: 202.2px; */
        margin: 0 45px 28px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 50px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 82.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        width: 261px;
    }
}

@media screen and (max-width: 786px) {
    .section1Desktop {
        display: none;
    }
    .iconStartMobile {
        width: 25px;
        height: 23px;
        padding: 8px;
    }
    .section1Mobile {
        display: block !important;
    }

    .h1TitleDiv {}

    .h1TitleDiv h1 {
        /* height: 164px;
        margin: -114px 41px -53px; */
        margin: 0 0 28px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #1f0d3c;
    }

    .titleFlex {
        font-size: 14px;
        letter-spacing: normal;
        color: #1f0d3c;
        text-align: right;
    }

    .iconStartMobile {
        width: 56px;
        height: 56px;
    }

    .phone1Mobile {
        width: 100%;
        margin: 0 0 0 0;
    }
    .titleAfterImg {
        text-align: end;
    }

    .titleAfterImg span {
        margin: 30px 41px 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 3.56;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .titleAfterImg h1 {
        height: 81px;
        margin: 0px 41px 12px 0;
        padding: 0 0 36px 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .paragraphSec1Mobile {
        height: 63px;
        margin: 11.4px 41px 0 14.5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    body {
        background-color: #f9f6ff !important;
    }

    .paragraphSec1Mobile2 {
        /* height: 202.2px; */
        margin: 0 45px 28px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        /* margin: 14.8px 14px 50px 0.5px; */
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 82.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        width: 261px;
    }

}

@media screen and (max-width: 396px) {
    .rec1Mobil {
        width: 99px !important;
        height: 109px !important;
        margin: 25px 0 0 0 !important;
        padding: 0 6px 0 13px !important;
    }
    .rec2Mobil {
        width: 99px !important;
        height: 109px !important;
        margin: 25px 0 0 0 !important;
        padding: 0 6px 0 13px !important;
    }
    .Rectangle_177Mobile {
        margin: 14px 2px 0 0 !important;
    }
}

    /* ----------------------------------SECTION2---------------------------------- */

.aboutSection31 {
    /* width: 693px;  */
    height: 180px;
    /* margin: 0px 0 0px 139px; */
    /* padding: 20px 17.9px 0 33px; */
    border-radius: 82px;
    background-color: #fff;
    color: #1f0d3c !important;
    margin: -10px 0 0px 0;
}

/* .aboutSection31:hover{
    background-color: #1f0d3c;
    color: #fff !important;
} */
.aboutSection31Active {
    /* width: 693px;  */
    height: 180px;
    /* margin: 0px 0 0px 139px; */
    /* padding: 20px 17.9px 0 33px; */
    border-radius: 82px;
    background-color: #1f0d3c;
    color: #fff !important;
    margin: 25px 0 53px 0px;
}


.aboutSection31Active span {
    width: 302px;
    /* height: 133px; */
    margin: 0 0 0 0;
    font-size: 31px;
    font-weight: 800;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    /* color: #1f0d3c;*/
}


.aboutSection32 {
    /* width: 693px; */
    height: 242px;
    margin: 35px 0 0px 149px;
    border-radius: 82px;
    background-color: #fff;
}

.aboutSection33 {
    /* width: 693px; */
    height: 242px;
    margin: 0px 0 0px 139px;
    padding: 20px 17.9px 0 33px;
    border-radius: 82px;
    background-color: #fff;
}
.sectionAb {
    width: fit-content;
    align-items: center;
}
.sectionAbout {
    padding-top: 150px;
    display: inline-grid;
    background-color: #fff;
    justify-items: center;
}

.imgCard {
    /* width: 203px;  */
    height: 190px;
    /* margin: 0 0 19.7px 4px; */
    padding: 36px 36px 36.1px 36px;
}

.aboutSection31 span {
    width: 302px;
    /* height: 133px; */
    margin: 0 0 0 0;
    font-size: 31px;
    font-weight: 800;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    /* color: #1f0d3c; */
}

/* .cardText{

} */
.cardText1 {
    width: 302px;
    height: 133px;
    margin: 35px 4px 54px 134px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.cardText2 {
    width: 302px;
    height: 133px;
    margin: 35px 4px 54px 134px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.h1section {
    height: 68px;
    /* margin: 0 73px 22px 342px; */
    font-size: 35px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.paragraphSection {
    width: 836px;
    height: 447px;
    /* margin: 0 0 0 47px; */
    font-size: 32px;
    font-weight: 600;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .aboutSection31 {
        /* width: 693px;  */
        height: 180px;
        /* margin: 0px 0 0px 139px; */
        /* padding: 20px 17.9px 0 33px; */
        border-radius: 82px;
        background-color: #fff;
        color: #1f0d3c !important;
        margin: 25px 0 25px 0px;
    }

    .cardText {
        width: 100%;
        height: 100px;
        margin: 0 0 0 0;
        font-size: 26px;
    }

    .aboutSection32 {
        width: 100%;
        height: 228px;
        margin: 25px 0 25px 0;
        padding: 0 0 0 0;
    }

    .aboutSection33 {
        width: 100%;
        height: 228px;
        margin: 25px 0 25px 0;
        padding: 0 0 0 0;
    }

    .paragraphSection {
        width: 100%;
        height: 447px;
        margin: 0 0 0 0;
        font-size: 22px;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        padding: 0 26px 0 56px;
    }

    .h1section {
        height: 67px;
        margin: 0 0 0 61px;
        font-size: 31px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        color: #1f0d3c;
        text-align: center;
    }

}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .section2Dectop {
        display: none;
    }
    .downApp {
        display: none !important;
    }

    .section2Mobile {
        display: block !important;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 11px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 40.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        margin: 0 0 0 49px;
        width: 101%;
    }

    .recMobileDiv {
        align-self: center;
        text-align: -webkit-center;
    }

    .gridRec1SpanEx span {
        color: #fff !important;
    }

    .paragraphSec2Mobile {
        height: 115px;
        margin: 48.9px 14.5px 10.2px 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .section2Dectop {
        display: none;
    }

    .section2Mobile {
        display: block !important;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 11px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 40.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        margin: 0 0 0 49px;
        width: 101%;
    }

    .recMobileDiv {
        align-self: center;
        text-align: -webkit-center;
    }

    .gridRec1SpanEx span {
        color: #fff !important;
    }

    .paragraphSec2Mobile {
        height: 115px;
        margin: 48.9px 14.5px 10.2px 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }
}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .section2Dectop {
        display: none;
    }

    .section2Mobile {
        display: block !important;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 11px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 40.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        margin: 0 0 0 49px;
        width: 101%;
    }

    .recMobileDiv {
        align-self: center;
        text-align: -webkit-center;
    }

    .gridRec1SpanEx span {
        color: #fff !important;
    }

    .paragraphSec2Mobile {
        height: 115px;
        margin: 48.9px 14.5px 10.2px 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }
}

@media screen and (max-width: 786px) {
    .section2Dectop {
        display: none;
    }

    .section2Mobile {
        display: block !important;
    }

    .globalRec {
        text-align: -webkit-center;
    }

    .rec1Mobile {
        width: 340px;
        height: 101px;
        margin: 14.8px 14px 11px 0.5px;
        border-radius: 34px;
        border: solid 2px #1f0d3c;
    }

    .gridRec1Img {
        width: 39.3px;
        height: 82.3px;
        margin: 6px 0 0 70px;
    }

    .gridRec1Span span {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: right;
        /* color: #1f0d3c; */
    }

    .gridRec1Span {
        align-self: center;
        margin: 0 0 0 49px;
        width: 101%;
    }

    .recMobileDiv {
        align-self: center;
        text-align: -webkit-center;
    }

    .gridRec1SpanEx span {
        color: #fff !important;
    }

    .paragraphSec2Mobile {
        height: 115px;
        margin: 48.9px 14.5px 10.2px 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

}

    
    /* ----------------------------------SECTION3---------------------------------- */

.aboutSection3_section31 {
    width: 693px;
    height: 242px;
    margin: 0px 0 0px 139px;
    padding: 20px 17.9px 0 33px;
    border-radius: 82px;
    background-color: #1f0d3c;
}

.aboutSection3_section32 {
    width: 693px;
    height: 242px;
    margin: 35px 0 0px 149px;
    border-radius: 82px;
    background-color: #fff;
}

.aboutSection3_section33 {
    width: 693px;
    height: 242px;
    margin: 0px 0 0px 139px;
    padding: 20px 17.9px 0 33px;
    border-radius: 82px;
    background-color: #fff;
}


.imgCard_section3 {
    width: 203px;
    height: 203px;
    margin: 0 0 19.7px 4px;
    padding: 36px 36px 36.1px 36px;
}

.cardText_section3 {
    width: 302px;
    height: 133px;
    margin: 35px 4px 54px 134px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
}

.cardText1_section3 {
    width: 302px;
    height: 133px;
    margin: 35px 4px 54px 134px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.cardText2_section3 {
    width: 302px;
    height: 133px;
    margin: 35px 4px 54px 134px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.h1section_section3 {
    height: 68px;
    margin: 100px 73px 22px 342px;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.paragraphSection_section3 {
    width: 836px;
    height: 447px;
    margin: 0 0 0 47px;
    font-size: 32px;
    font-weight: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.divDetails {
    padding-bottom: 120px;
}

.appSection {
    width: 100%;
    height: 400px;
    padding: 4px 0 0;
    background-image: url('../../../assets/images/img.png');
}

.appsection1 p {
    width: 685px;
    height: 60px;
    margin: 79px 0 0 0px;
    font-size: 32px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.appsection1 h1 {
    height: 210px;
    margin: 0 253px 0 2px;
    font-size: 54px;
    font-weight: bold;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}














@media screen and (max-width: 1820px) and (min-width: 1115px) {
    .aboutSection3_section31 {
        width: 100%;
        margin: 10px 0 0 0;
    }

    .aboutSection3_section32 {
        width: 100%;
        height: 228px;
        margin: 25px 0 25px 0;
        padding: 0 0 0 0;

    }

    .aboutSection3_section33 {
        width: 100%;
        height: 228px;
        margin: 25px 0 25px 0;
        padding: 0 0 0 0;


    }

    .paragraphSection_section3 {
        width: 100%;
        height: 447px;
        margin: 0 0 0 0;
        font-size: 22px;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        padding: 0 26px 0 56px;

    }

    .h1section_section3 {
        margin: 0 0 0 0;
        width: 100%;
        text-align: center;
    }


    .appsection1 p {
        width: 685px;
        height: 60px;
        margin: 79px 0 0 0px;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .appsection1 h1 {
        font-size: 54px;
        font-weight: bold;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        width: 685px;
    }

}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .sec3Desktop {
        display: none;
    }

    .sec3Mobile {
        display: block !important;
    }

    .recDiv {
        background-image: url('../../../assets/images/img2.png');
        height: 324px;
        padding: 34px 45px 22.9px 54px;
        background-color: #fec868;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 50px 0 0 0;
    }

    .appsection1Mobile p {
        height: 37px;
        margin: 0 0 6px 130px;
        font-size: 20px;
        line-height: 2.4;
        letter-spacing: normal;
        font-weight: 500;
        text-align: right;
        color: #1f0d3c;
    }

    .appsection1Mobile h1 {
        height: 77px;
        margin: 6px 0 90px;
        font-size: 24px;
        font-weight: 800;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        padding: 0 0 0 121px;
    }
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .sec3Desktop {
        display: none;
    }

    .sec3Mobile {
        display: block !important;
    }

    .recDiv {
        background-image: url('../../../assets/images/img2.png');
        height: 324px;
        padding: 34px 45px 22.9px 54px;
        background-color: #fec868;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 50px 0 0 0;
    }

    .appsection1Mobile p {
        height: 37px;
        margin: 0 0 6px 130px;
        font-size: 20px;
        line-height: 2.4;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .appsection1Mobile h1 {
        height: 77px;
        margin: 6px 0 90px;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        padding: 0 0 0 121px;
    }

}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .sec3Desktop {
        display: none;
    }

    .sec3Mobile {
        display: block !important;
    }

    .recDiv {
        background-image: url('../../../assets/images/img2.png');
        height: 324px;
        padding: 34px 45px 22.9px 54px;
        background-color: #fec868;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 50px 0 0 0;
    }

    .appsection1Mobile p {
        height: 37px;
        margin: 0 0 6px 130px;
        font-size: 20px;
        line-height: 2.4;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .appsection1Mobile h1 {
        height: 77px;
        margin: 6px 0 90px;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        padding: 0 0 0 121px;
    }
}

@media screen and (max-width: 786px) {
    .sec3Desktop {
        display: none;
    }

    .sec3Mobile {
        display: block !important;
    }

    .recDiv {
        background-image: url('../../../assets/images/img2.png');
        height: 324px;
        padding: 34px 45px 22.9px 54px;
        background-color: #fec868;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 50px 0 0 0;
    }

    .appsection1Mobile p {
        height: 37px;
        margin: 0 0 6px 130px;
        font-size: 20px;
        line-height: 2.4;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
    }

    .appsection1Mobile h1 {
        height: 77px;
        margin: 6px 0 90px;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: #1f0d3c;
        padding: 0 0 0 0;
    }
}
/* ----------------------------------SECTION4---------------------------------- */

.section4Header {
    text-align: -webkit-center;
}

.section4Header h1 {
    /* height: 170px; */
    /* margin: 0px 14px 178px 44px; */
    padding: 50px 0 43px 0;
    font-size: 47px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #1f0d3c;
    width: 60%;
}

.rec1 {
    /* width: 360px; */
    height: 360px;
    /* margin: 100px 32px 0 100px; */
    padding: 125px 57px 31px 39px;
    border-radius: 82px;
    background-color: #fee0d5;
}

.rec2 {
    /* width: 360px; */
    /* height: 360px; */
    /* margin: 100px 176px 0 32px; */
    padding: 117px 57px 31px 39px;
    border-radius: 82px;
    background-color: #1f0d3c;

}


.Rectangle_177 {
    width: 108px;
    height: 122px;
    margin: 29px 8px 0 0;
    /* opacity: 0.11; */
    border-radius: 41px;
    /* background-color: #d26a44; */
}


.spanRec1 {
    width: 148px;
    height: 127px;
    margin: 0 0 77px 8px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.spanRec2 {
    width: 148px;
    height: 127px;
    margin: 0 0 77px 8px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: right;
    color: #fff
}

.rec1Explorer {
    width: 76px;
    height: 45px;
    margin: 0 0 0 246px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #1f0d3c;
}

.rec1Svg {
    width: 49px;
    height: 34px;
    padding: 4px;
}








@media screen and (max-width: 1820px) and (min-width: 1115px) {

    .rec1,
    .rec2 {
        margin: 0 0 0 0;
    }

}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    .sec4Desktop {
        display: none;
    }

    .sec4Mobile {
        display: block !important;
    }


    .rec1Mobil {
        width: 126px;
        height: 126px;
        border-radius: 36px;
        background-color: #fee0d5;
        margin: 60px 27px 0 0;
        text-align: right;
        padding: 0 6px 0 13px;
    }

    .rec2Mobil {
        width: 126px;
        height: 126px;
        border-radius: 36px;
        background-color: #1f0d3c;
        margin: 60px 27px 0 0;
        text-align: right;
        padding: 0 6px 0 13px;
        color: #fff;

    }

    .Rectangle_177Mobile {
        width: 38px;
        height: 42px;
        margin: 29px 2px 0 0;
        /* opacity: 0.11; */
        border-radius: 41px;
        /* background-color: #d26a44; */
    }


    .spanRec1Mobile {
        width: 47px;
        height: 40px;
        margin: 0 17px 31px 2px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: right;
        color: var(--eggplant);

    }

    .spanRec2Mobile {
        width: 148px;
        height: 127px;
        margin: 0 0 77px 8px;
        font-size: 38px;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: right;
        color: #fff
    }

    .rec1ExplorerMobile {
        width: 25px;
        height: 15px;
        font-size: 8px;
        font-weight: 600;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #1f0d3c;
    }

    .rec1SvgMobile {
        width: 32px;
        height: 24px;
        padding: 4px;
    }

}

@media screen and (max-width: 984px) and (min-width: 940px) {
    .sec4Desktop {
        display: none;
    }

    .sec4Mobile {
        display: block !important;
    }


    .rec1Mobil {
        width: 126px;
        height: 126px;
        border-radius: 36px;
        background-color: #fee0d5;
        margin: 60px 27px 0 0;
        text-align: right;
        padding: 0 6px 0 13px;
    }

    .rec2Mobil {
        width: 126px;
        height: 126px;
        border-radius: 36px;
        background-color: #1f0d3c;
        margin: 60px 27px 0 0;
        text-align: right;
        padding: 0 6px 0 13px;
        color: #fff;

    }

    .Rectangle_177Mobile {
        width: 38px;
        height: 42px;
        margin: 29px 2px 0 0;
        /* opacity: 0.11; */
        border-radius: 41px;
        /* background-color: #d26a44; */
    }


    .spanRec1Mobile {
        width: 47px;
        height: 40px;
        margin: 0 17px 31px 2px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: right;
        color: var(--eggplant);

    }

    .spanRec2Mobile {
        width: 148px;
        height: 127px;
        margin: 0 0 77px 8px;
        font-size: 38px;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: right;
        color: #fff
    }

    .rec1ExplorerMobile {
        width: 25px;
        height: 15px;
        font-size: 8px;
        font-weight: 600;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #1f0d3c;
    }

    .rec1SvgMobile {
        width: 32px;
        height: 24px;
        padding: 4px;
    }


}

@media screen and (max-width: 940px) and (min-width: 786px) {
    .sec4Desktop {
        display: none;
    }

    .sec4Mobile {
        display: block !important;
    }


    .rec1Mobil {
        width: 126px;
        height: 126px;
        border-radius: 36px;
        background-color: #fee0d5;
        margin: 60px 27px 0 0;
        text-align: right;
        padding: 0 6px 0 13px;
    }

    .rec2Mobil {
        width: 126px;
        height: 126px;
        border-radius: 36px;
        background-color: #1f0d3c;
        margin: 60px 27px 0 0;
        text-align: right;
        padding: 0 6px 0 13px;
        color: #fff;

    }

    .Rectangle_177Mobile {
        width: 38px;
        height: 42px;
        margin: 29px 2px 0 0;
        /* opacity: 0.11; */
        border-radius: 41px;
        /* background-color: #d26a44; */
    }


    .spanRec1Mobile {
        width: 47px;
        height: 40px;
        margin: 0 17px 31px 2px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: right;
        color: var(--eggplant);

    }

    .spanRec2Mobile {
        width: 148px;
        height: 127px;
        margin: 0 0 77px 8px;
        font-size: 38px;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: right;
        color: #fff
    }

    .rec1ExplorerMobile {
        width: 25px;
        height: 15px;
        font-size: 8px;
        font-weight: 600;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #1f0d3c;
    }

    .rec1SvgMobile {
        width: 32px;
        height: 24px;
        padding: 4px;
    }
    .downApp{
        display:none !important;
    }

}










@media screen and (max-width: 786px) {
    .sec4Desktop {
        display: none;
    }

    .sec4Mobile {
        display: block !important;
    }


    .rec1Mobil {
        width: 116px;
        height: 124px;
        border-radius: 36px;
        background-color: #fee0d5;
        text-align: right;
        /* margin: 25px 0 0 0; */
        padding: 0 6px 0 13px;
    }

    .rec2Mobil {
        width: 116px;
        height: 124px;
        padding: 0 0 0 9px;
        border-radius: 36px;
        text-align: right;
        color: #fff;
        background-color: #1f0d3c;

    }

    .Rectangle_177Mobile {
        width: 38px;
        height: 42px;
        margin: 29px 2px 0 0;
        /* opacity: 0.11; */
        border-radius: 41px;
        /* background-color: #d26a44; */
    }


    .spanRec1Mobile {
        width: 47px;
        height: 40px;
        margin: 0 17px 31px 2px;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: right;
        color: var(--eggplant);

    }

    .spanRec2Mobile {
        width: 148px;
        height: 127px;
        margin: 0 0 77px 8px;
        font-size: 38px;
        font-weight: bold;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: right;
        color: #fff
    }

    .rec1ExplorerMobile {
        width: 25px;
        height: 15px;
        font-size: 8px;
        font-weight: 600;
        line-height: 1.88;
        letter-spacing: normal;
        text-align: left;
        color: #1f0d3c;
    }

    .rec1SvgMobile {
        width: 32px;
        height: 24px;
        padding: 4px;
    }

    .downApp{
        display:none;
    }
}

/* -------ANIMATION--------- */
@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideInUp {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.headerH1 {
    animation-duration: 2s;
    animation-name: slideInLeft;
}

.playVid {
    animation-duration: 2s;
    animation-name: slideInRight;
}

.phoneImg1 {
    animation-duration: 2s;
    animation-name: slideInRight;
}

.phoneImg2 {
    animation-duration: 3s;
    animation-name: slideInRight;
}

.phoneImg3 {
    animation-duration: 4s;
    animation-name: slideInRight;
}

.aboutDef {
    animation-duration: 2s;
    animation-name: slideInLeft;
}

.section2Dectop {
    animation-duration: 2s;
    animation-name: slideInLeft;
    padding-bottom: 50px;
    background-color: white;
}

.sec4Desktop {
    animation-duration: 2s;
    animation-name: slideInLeft;
}

.centerAlign {
    text-align: -webkit-center;
}


.rec1MobileActive {
    width: 340px;
    height: 101px;
    margin: 14.8px 14px 50px 0.5px;
    border-radius: 34px;
    border: solid 2px #1f0d3c;
    background-color: #1f0d3c;
    color: white;
}

.rec1MobileActive span {
    color: #fff;
}

.pathImg img {
    width: 60%;
    margin: 30px 0 0 102px;
}





.logo1{

}

.Ellipse {
    width: 52px;
    height: 52px;
    margin: 0 0 0 12px;
    /* padding: 6px; */
    border: solid 2px #fec868;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}
.Ellipse:hover {
    background-color: #fec868;
}

.Ellipse6 {
    width: 40px;
    height: 40px;
    /* padding: 11.3px 9.2px 10.3px 14.4px; */
    box-shadow: 0 3px 6px 0 #1f0d3c;
    border: solid 2px #1f0d3c;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}







.downApp {
    width: 100%;
    height: 480px;
    /* padding: 111px 140px 110px; */
    background-color: #fec868;

}

.Path406 {
    height: 481px;
    position: relative;
    /* margin-left: 37%; */
  }






  

.buyBtn {
    /* width: 151px;
    height: 79px; */
    /* margin: 30px 0 336px 21.4px; */
    /* padding: 27px 30px 28px; */
    /* padding: 18px 0 0 0; */
    border-radius: 25px;
    background-color: #1f0d3c;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 178px;
    height: 92px;
    margin: 38px 0 0 0;

    display: flex;
    flex-direction: column;


}
/* .buyBtn:hover{
    background-color: #fec868 !important;
} */

.buyBtn:hover .iconDown1Span {
    opacity: 0;
    transition: all .8s ease-in-out;
}
.buyBtn:hover .iconDown2Span {
    opacity: 0;
    transition: all .8s ease-in-out;
}
.buyBtn:hover .icondown {
    position: relative;
    top: 0;
    transition: all .8s ease-in-out;
    transform: translateX(30px);
}



.buyBtn:hover .platform {
    opacity: 0;
    transition: all .8s ease-in-out;
}

.icondown {
    width: 42px;
    height: 42px;
    /* margin: -7px 0 0 0; */
    fill: #1f0d3c;
}


.iconDown1Span {
    font-size: 9px;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.iconDown2Span {
    margin: 15px 2px 0 0;
    font-size: 14px;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}



.canYouText{
    height: 60px;
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.titleDownApp{
    height: 210px;
    font-size: 52px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: right;
    color: #1f0d3c;
}

.secGrid{
    margin: 0 221px 0 -210px;
    width: 100%;
}


.redCircle{
    width: 30px;
    height: 30px;
    margin: 0 11px 0 0;
    border: solid 6px #FE3A83;
    background-color: #fff;
    border-radius: 100%;
}

.redPolygon{
    width: 30px;
    height: 30px;
    margin: 0 11px 0 0;
    border: solid 6px #FE3A83;
    background-color: #fff;
    transform: rotate(316deg);
    border-radius: 31%;
}

.redTriangle{
    width: 30px;
    height: 25px;
    transform: rotate(181deg);
    right: 10px;
    border: solid 6px #FE3A83;
    background-color: #fff;
}