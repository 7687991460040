.footer{
    background-color:#f9f6ff;
    padding-top: 69px;
}
.footerbtn1{
    width: 74px;
    height: 74px;
    padding: 25px;
    border-radius: 15px;
    background-color: #1f0d3c; 
    margin: 93px 11px 70px;
}

.svgfooter:hover {
    transform: translate(0px, 5px);
}

.svgfooter{
    color:white;
}


.footerbtn2{
    width: 74px;
    height: 74px;
    padding: 25px;
    margin: 93px 3px 70px;
    border-radius: 15px;
    background-color: #1f0d3c
}

.footerbtn3{
    width: 74px;
    height: 74px;
    padding: 25px;
    margin: 93px 23px 70px 16px;
    border-radius: 15px;
    background-color: #1f0d3c;
}

.FooterDiv{
    place-self: stretch;
    margin-inline-end: auto;
    margin-left: -56px;

}
.FooterDiv h6{
    height: 60px;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: normal;
    text-align: end;
}

.footerParagraph{
    height: 52px;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
}
.footerParagraph2{
    height: 52px;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: right; 
    margin-top:16px
}
.footerParagraph2_2{
    height: 52px;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
}
.logoFooter{
    width: 284px; 
    height: 75.7px;
    object-fit: contain
}
.SulogoFooter{
    width: 188.4px;
    height: 17px;
    object-fit: contain;
    margin: 0 0 0 46px
}
.FooterDiv_2{
    height: 120px;
    background-color: #1f0d3c;
    align-items: center;
}
.footCopyright{
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 3.2px;
    text-align: right;
    color: #f7f9fa;
}
.footerCreated{
    height: 43px;
    margin: 22px 92px 0 0;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2.8px;
    text-align: right;
    color: #f7f9fa;
}

@media screen and (max-width: 1820px) and (min-width: 1115px) {
    h6 {
        font-size: 22px !important;
    }
    .footerParagraph {
        font-size: 20px !important;
    }
    .footCopyright{
        font-size: 18px !important;
        margin: 0 0 0 0 !important;
    }
    .footerCreated{
        font-size: 15px;
    }
    .FooterDiv{
    margin-left: -122px;
    }
}

@media screen and (max-width: 1115px) and (min-width: 984px) {
    h6 {
        font-size: 18px !important;
    }
    .footerParagraph, .footerParagraph2, .footerParagraph2_2, .footerParagraph2_2 {
        font-size: 18px !important;
    }
    .footerbtn1 , .footerbtn2, .footerbtn3 {
        width: 74px;
        height: 54px;
        padding: 17px;
    }


    .logoFooter {
        width: 184px; 
        height: 75.7px;
        object-fit: contain
    }
    .SulogoFooter {
        width: 143.4px;
        height: 13px;
        object-fit: contain;
        margin: 0 0 0 27px;
    }
    .footCopyright{
        font-size: 15px !important;
        margin: 0 0 12px 0 !important;
        justify-self: center;
    }
    .footerCreated{
        font-size: 12px;
        margin: 0 0 0 0 !important;
    }
}

@media screen and (max-width: 984px) and (min-width: 940px) {
    h6 {
        font-size: 18px !important;
    }
    .footerParagraph, .footerParagraph2, .footerParagraph2_2, .footerParagraph2_2 {
        font-size: 18px !important;
    }
    .footerbtn1 , .footerbtn2, .footerbtn3{
        width: 50px;
        height: 54px;
        padding: 17px;
    }
    .logoFooter {
        width: 184px; 
        height: 75.7px;
        object-fit: contain
    }
    .SulogoFooter {
        width: 143.4px;
        height: 13px;
        object-fit: contain;
        margin: 0 0 0 27px;
    }
    .footCopyright{
        font-size: 12px !important;
        margin: 0 0 0 0 !important;
    }
    .footerCreated{
        font-size: 10px;
    }
}

@media screen and (max-width: 940px) and (min-width: 786px) {
    h6 {
        font-size: 18px !important;
    }
    .footerParagraph, .footerParagraph2, .footerParagraph2_2, .footerParagraph2_2 {
        font-size: 18px !important;
    }
    .logoFooter {
        display: block;
        width: 184px; 
        height: 75.7px;
        object-fit: contain
    }
    .SulogoFooter {
        display: block;
        width: 143.4px;
        height: 13px;
        object-fit: contain;
        margin: 0 0 0 27px;
    }
    .footCopyright{
        font-size: 12px !important;
        margin: 0 0 0 0 !important;
    }
    .footerCreated{
        font-size: 10px;
    }
}

@media screen and (max-width: 786px)  {
    .footerDesktop{
        display: none;
    }
    .footerMobile{
        display: block !important;
        /* padding-top: 0px !important; */
        
    }
    .footCopyrightMobile{
        /* width: 131px; */ 
        /* height: 11px; */
        font-size: 6px;
        /* line-height: 2.67; */
        letter-spacing: 0.6px;
        /* text-align: right; */
        color: #f7f9fa;
        /* margin: 0 0 0 145px; */
    }
    .footerCreatedMobile{
      /* height: 7px; */
    margin: 0 32px 0 0;
    font-size: 6px;
    /* letter-spacing: 0.4px; */
    text-align: right;
    color: #f7f9fa;
    }
    .FooterDivMobile{
        height: 40px;
        margin: 105px 0px 0 0;
        background-color: #1f0d3c;
        align-items: center;
    }
    .footerMobilefooter{
        background-color: #1f0d3c;
        width: 100%;
        height: 40px;
        /* padding: 15px 26px 14px 136px; */
        /* display: flex;
    align-items: center;
    justify-content: center; */
    }
}


.section2LaptopVeisuble {
    opacity: 0;
}